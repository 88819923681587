<template>
  <el-select
      filterable
      style="width: 100%;"
      v-model="selectedProject"
  >
    <el-option
        v-if="allOption"
        value=""
    >{{ $t('system.all') }}
    </el-option>
    <el-option
        :label="project.name"
        v-bind:key="project.id"
        v-bind:value="project.id"
        v-for="project in generateProjectPrefixes"
    ></el-option>
  </el-select>
  <!--<select class="form-control" v-model="selectedProject">-->
  <!--<option v-if="allOption" value="">{{$t('system.all')}}</option>-->
  <!--<option v-for="project in generateProjectPrefixes" v-bind:value="project.id" v-bind:key="project.id">-->
  <!--{{project.name}}-->
  <!--</option>-->
  <!--</select>-->
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import notification from '../../notification/notify';

export default {
  name: 'projectsSelectList',
  components: {},
  computed: {
    generateProjectPrefixes: function () {
      let outputTree = [];
      for (let i = 0; i < this.projectsTree.length; i++) {
        let level = 0;
        for (let j = 0; j < this.projectsTree[i].length; j++) {
          if (j > 0) {
            if ((this.projectsTree[i][j - 1].level < this.projectsTree[i][j].level)) {
              level++;
            }
            if ((this.projectsTree[i][j - 1].level > this.projectsTree[i][j].level)) {
              level = this.projectsTree[i][j].level;
            }
          }
          outputTree.push({
            name: this.createLevelName(this.projectsTree[i][j].name, level),
            id: this.projectsTree[i][j].id,
          });
        }
      }
      return outputTree;
    },
  },
  props: ['initialValue', 'allOption', 'value'],
  watch: {
    'selectedProject': function () {
      this.$emit('input', this.selectedProject);
    },
    'initialValue': function () {
      this.selectedProject = this.initialValue;
    },
    'value': function (value) {
      this.selectedProject = value;
    },
  },
  data() {
    return {
      selectedProject: null,
      projectsTree: [],
    };
  },

  created: function () {
    this.selectedProject = this.initialValue;
  },

  beforeMount() {
    this.getProjectsTree();
  },
  mounted() {
    this.selectedProject = '';
  },

  methods: {
    getProjectsTree() {
      let vm = this;
      axios.get('projects_tree_flat', {
        params: {},
      })
          .then(function (response) {
            Vue.set(vm.$data, 'projectsTree', response.data.model);
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    createLevelName(name, level) {
      let prefix = '';
      for (let i = 0; i < level; i++) {
        prefix = prefix + '>';
      }
      return prefix + ' ' + name;
    },
  },
};
</script>
