<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <div class="d-flex flex-wrap justify-content-between align-center">
          <b>{{ $t('system.total_time') }}: {{ totalHours }} {{ $t('system.hours') }}</b>
          <div class="d-flex justify-content-end report--selectFilter">
            <el-select
                :placeholder="$t('report.users')"
                @input="fetchIssues"
                clearable
                collapse-tags
                filterable
                multiple
                size="small"
                v-model="form.user_id"
            >
              <el-option
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  v-for="(item) in orderedUsers"
              >
              </el-option>
            </el-select>
            <el-select
                :placeholder="$t('report.project')"
                @input="fetchIssues"
                class="ml-3"
                clearable
                collapse-tags
                filterable
                multiple
                size="small"
                v-model="form.project_id"
            >
              <el-option
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  v-for="(item) in orderedProjects"
              >
              </el-option>
            </el-select>
            <el-select
                :placeholder="$t('report.status')"
                @input="fetchIssues"
                class="ml-3"
                clearable
                collapse-tags
                filterable
                multiple
                size="small"
                v-model="form.status_id"
            >
              <el-option
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  v-for="(item) in option.statuses"
              >
              </el-option>
            </el-select>
            <el-date-picker
                :picker-options="pickerOptions"
                @change="fetchIssues"
                align="right"
                class="ml-3"
                format="yyyy-MM-dd"
                size="small"
                type="daterange"
                v-model="dateRange"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
        <!--        <div class="pb-2 pt-2">-->
        <!--          <el-tag-->
        <!--              v-for="tag in tags"-->
        <!--              :key="tag.name + tag.type + tag.id"-->
        <!--              :type="tag.color"-->
        <!--              closable-->
        <!--              class="d-inline-flex align-items-center m-2 ml-0"-->
        <!--              @close="handleClose(tag)">-->
        <!--                <span style="max-width: 400px; overflow: hidden; text-overflow: ellipsis; display: inline-block;">{{tag.name }}</span>-->
        <!--          </el-tag>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="view brd-none">
      <div class="view-body ">
        <DataTable
            :headers="thead"
            :loading="loading"
            :model="issues"
            :params.sync="params"
            @updateData="fetchIssues"
            idPath="issues.id"
        >
          <template v-slot:item.issues.id="{row}">
            <div class="d-flex mx-2">
              <span class="cut-text">
                #{{ row['issues.id'] }} {{ row['issues.subject'] }}
              </span>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
    <div
        class="view"
        v-permission="['payroll-generate']"
    >
      <div class="view-heading">
        <div class="view-title">
          {{ $t('system.generation') }}
        </div>
      </div>
      <div class="view-body">
        <el-button
            :disabled="issues.length < 1"
            :loading="docLoading"
            @click.prevent.stop="generateFile('docx')"
            plain
            type="success"
        >
          {{ $t('system.doc') }}
        </el-button>
        <el-button
            :disabled="issues.length < 1"
            :loading="pdfLoading"
            @click.prevent.stop="generateFile('pdf')"
            plain
            type="info"
        >
          {{ $t('system.pdf') }}
        </el-button>
        <el-dropdown @command="generatePreviousMonthReport">
          <el-button
              :loading="previousMonthReportLoading"
              class="ml-2"
              type="primary"
          >
            {{ $t('system.previous_month') }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="pdf">PDF</el-dropdown-item>
            <el-dropdown-item command="doc">DOC</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown
            @command="generateEmployeeReport"
            v-if="ifHasPermission(['aberit-payroll'])"
        >
          <el-button
              :loading="employeeReportLoading"
              class="ml-2"
              type="primary"
          >
            {{ $t('system.employee_month_report') }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="pdf">PDF</el-dropdown-item>
            <el-dropdown-item command="doc">DOC</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <!--        <el-button @click="generatePreviousMonthReport('pdf')" type="info" plain :loading="previousMonthReportLoading">{{$t('system.previous_month_pdf')}}</el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import {mapActions, mapState} from 'vuex';
import projectsSelectList from '../../components/projectsTree/projectsSelectList.vue';
import StaticTable from '../../components/ABtable/StaticTable.vue';
import notification from '../../notification/notify';
import DataTable from '@/components/Table/DataTable';
import ContentWrapper from '@/components/HOCs/ContentWrapper';
import {downloadReport} from '@/helpers/files';
// import IssueCreate from '../../components/dialog/IssueCreate.vue'

// TODO: refactor and split into smaller components
export default {
  name: 'ReportEmployee',
  components: {
    ContentWrapper,
    DataTable,
    projectsSelectList,
    StaticTable,
    // IssueCreate
  },
  data() {
    return {
      totalHours: 0,
      params: {
        column: 'issues.id',
        direction: 'asc',
        per_page: 10,
        page: 1,
        total: 0,
        per_page_sizes: [10, 30, 50, 100],
      },
      issues: [],
      idUsers: [],
      idProjects: [],
      idStatuses: [],
      prevUsers: [],
      prevStatuses: [],
      prevProjects: [],
      tags: [],
      model: {
        issue: {},
      },
      form: {
        user_id: null,
        status_id: null,
        project_id: null,
        date_picker: 'between',
        // date_range: [new Date().getTime() - 3600 * 1000 * 24 * 7, new Date()]
      },
      thead: [
        {
          label: 'report.issue_number',
          key: 'issues.id',
          sort: true,
        },
        {
          label: 'report.client_name',
          key: 'client',
          sort: true,
        },
        {
          label: 'report.issue_status',
          key: 'status',
          sort: true,
        },
        {
          label: 'report.activity',
          key: 'activity',
          sort: true,
        },
        {
          label: 'report.user',
          key: 'time_entries_users.name',
          sort: true,
        },
        {
          label: 'report.author',
          key: 'issues_users.name',
          sort: true,
        },
        {
          label: 'report.comments',
          key: 'time_entries.comments',
          sort: true,
          style: {
            minWidth: '380px',
          },
          deleteWrap: true,
        },
        {
          label: 'report.issue_created_at',
          key: 'issues.created_at',
          sort: true,
        },
        {
          label: 'report.time_entires_created',
          key: 'time_entries.spent_on',
          sort: true,
        },
        {
          label: 'report.time_entires_hours',
          key: 'time_entries.hours',
          sort: true,
        },
      ],
      documentForm: {
        user_id: null,
        status_id: null,
        project_id: null,
        date_picker: 'between',
        date_range: [null, new Date()],
      },
      option: {},
      resource: 'payroll',
      action: 'index',
      idShowModalTask: 0,
      mode: 0,
      type: 'employee',
      checked: [],
      options: [],
      loading: true,
      docLoading: false,
      pdfLoading: false,
      previousMonthReportLoading: false,
      employeeReportLoading: false,
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: this.$t('date.today'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: this.$t('date.last_week'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: this.$t('date.last_month'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: this.$t('date.last_three_month'),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
    };
  },
  beforeMount() {
    this.$store.commit('SET_ACTIVE', 'report');
    this.fetchIssues();
  },
  computed: {
    ...mapState('payroll', {vuexDateRange: 'dateRange'}),
    dateRange: {
      get() {
        return this.vuexDateRange;
      },
      set(newVal) {
        this.setDateRange(newVal);
      },
    },
    getMode: function () {
      return this.mode;
    },
    orderedUsers: function () {
      let vm = this;
      let users = [];
      _.forEach(vm.option.users, function (name, id) {
        var item = [];
        item.id = id;
        item.name = name;
        users.push(item);
      });
      let usersSorted = _.orderBy(users, [item => item.name.toLowerCase()], ['asc']);
      return usersSorted;

    },
    orderedProjects() {
      if (!_.isEmpty(this.option.projects)) {
        let currentProject = [];
        _.forEach(this.option.projects, function (name, id) {
          var item = [];
          item.id = id;
          item.name = name;
          currentProject.push(item);
        });
        let projects = _.orderBy(currentProject, [item => item.name.toLowerCase()], ['asc']);
        return projects;
      } else {
        return {};
      }
    },
  },
  methods: {
    ...mapActions('payroll', ['setDateRange']),
    notifyAboutDeletedIssue() {
      let vm = this;
      notification.notify(
          vm.$t('notify.error'),
          vm.$t('system.deleted_issue'),
          'error');
    },
    sort(column) {

      if (column === this.params.column) {
        if (this.params.direction === 'desc') {
          this.params.direction = 'asc';
        } else {
          this.params.direction = 'desc';
        }
      } else {
        this.params.column = column;
        this.params.direction = 'asc';
      }
      this.fetchIssues();
    },
    selectValUsers(data) {
      if (this.prevUsers.length > data.length) {
        let id = _.difference(this.prevUsers, data)[0]; // [88,89] -> [89] => 88 (id)
        let index = null;
        for (let tagIdx in this.tags) {
          let tag = this.tags[tagIdx];
          if (tag.id === id && tag.type === 'user_id') {
            index = tagIdx;
          }
        }
        if (index) {
          this.tags.splice(index, 1);
        }
      } else {
        let id = data[data.length - 1];
        this.tags.push({
          id: id,
          name: _.find(this.orderedUsers, {id: id}).name,
          type: 'user_id',
          color: '',
        });
      }
      this.prevUsers = data;
    },
    selectValProjects(data) {
      if (this.prevProjects.length > data.length) {
        let id = _.difference(this.prevProjects, data)[0]; // [88,89] -> [89] => 88 (id)
        let index = null;
        for (let tagIdx in this.tags) {
          let tag = this.tags[tagIdx];
          if (tag.id === id && tag.type === 'project_id') {
            index = tagIdx;
          }
        }
        if (index) {
          this.tags.splice(index, 1);
        }
      } else {
        let id = data[data.length - 1];
        this.tags.push({
          id: id,
          name: _.find(this.orderedProjects, {id: id}).name,
          type: 'project_id',
          color: 'success',
        });
      }
      this.prevProjects = data;
    },
    selectValStatuses(data) {
      if (this.prevStatuses.length > data.length) {
        let id = _.difference(this.prevStatuses, data)[0]; // [88,89] -> [89] => 88 (id)
        let index = null;
        for (let tagIdx in this.tags) {
          let tag = this.tags[tagIdx];
          if (tag.id === id && tag.type === 'status_id') {
            index = tagIdx;
          }
        }
        if (index) {
          this.tags.splice(index, 1);
        }
      } else {
        let id = data[data.length - 1];
        this.tags.push({
          id: id,
          name: _.find(this.option.statuses, {id: id}).name,
          type: 'status_id',
          color: 'info',
        });
      }
      this.prevStatuses = data;
    },
    handleClose(tag) {
      let indexOfSelect = this.form[tag.type].indexOf(tag.id);
      this.form[tag.type].splice(indexOfSelect, 1);
      let indexOfTag = this.tags.indexOf(tag);
      this.tags.splice(indexOfTag, 1);
    },
    async generateEmployeeReport(type) {
      this.employeeReportLoading = true;
      try {
        const response = await axios.get('payroll/employees_report', {
              responseType: 'blob',
              params: {type},
            },
        );
        downloadReport(response);
      } catch (e) {
        notification.notify(
            this.$t('system.error'),
            this.$t('system.report_was_not_generated'),
            'error',
        );
      } finally {
        this.employeeReportLoading = false;
      }
    },
    async generatePreviousMonthReport(type) {
      this.previousMonthReportLoading = true;
      try {
        const response = await axios.get('payroll/users_report', {
              responseType: 'blob',
              params: {type},
            },
        );
        downloadReport(response);
      } catch (e) {
        console.log('e', e);
        notification.notify(
            this.$t('system.error'),
            this.$t('system.report_was_not_generated'),
            'error',
        );
      } finally {
        this.previousMonthReportLoading = false;
      }
    },
    generateFile(type) {
      if (type === 'docx') {
        this.docLoading = true;
      } else if (type === 'pdf') {
        this.pdfLoading = true;
      }
      let dateStart = null;
      let dateEnd = null;
      if (this.dateRange != null) {
        if (this.dateRange[0] !== null) {
          dateStart = moment(this.dateRange[0]).format('YYYY-MM-DD');
          dateEnd = moment(this.dateRange[1]).format('YYYY-MM-DD');
        }
      }
      axios.get('payroll/generate', {
        responseType: 'blob',
        params: {
          date_picker: this.form.date_picker,
          date_start: dateStart,
          date_end: dateEnd,
          status: this.form.status_id,
          project_id: this.form.project_id,
          user_id: this.form.user_id,
          ...this.params,
          document: type,
          type,
        },
      })
          .then(response => {
            downloadReport(response);
          })
          .catch(function (error) {
            console.log('error', error);
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          })
          .finally(() => {
            if (type === 'docx') {
              this.docLoading = false;
            } else if (type === 'pdf') {
              this.pdfLoading = false;
            }
          });
      // }
    },
    buildURL(mode, form, document, checkbox) {
      let settled = false;
      let action = 'report';
      if (document !== null) {
        action = 'generate';
      }
      let dateStart = null;
      let dateEnd = null;
      if (this.dateRange[0] !== null) {
        dateStart = moment(this.dateRange[0]).format('YYYY-MM-DD');
        dateEnd = moment(this.dateRange[1]).format('YYYY-MM-DD');
      }
      let status = form.status_id;
      return `${this.resource}/${action}?settled=${settled}&date_start=${dateStart}&date_end=${dateEnd}&status=${status}&document=${document}&type=${this.type}&project_id=${form.project_id}&user_id=${form.user_id}&checked=${checkbox}`;
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchIssues();
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchIssues();
    },
    next() {
      if (this.model.next_page_url) {
        this.params.page++;
        this.fetchIssues();
      }
    },
    prev() {
      if (this.model.prev_page_url) {
        this.params.page--;
        this.fetchIssues();
      }
    },
    fetchIssues() {
      let user = this.$store.getters['auth/user'];
      let vm = this;
      let dateStart = null;
      let dateEnd = null;
      if (this.dateRange != null) {
        if (this.dateRange[0] !== null) {
          dateStart = moment(this.dateRange[0]).format('YYYY-MM-DD');
          dateEnd = moment(this.dateRange[1]).format('YYYY-MM-DD');
        }
      }
      vm.loading = true;
      axios.get('payroll/report', {
        params: {
          date_picker: this.form.date_picker,
          date_start: dateStart,
          date_end: dateEnd,
          status: this.form.status_id,
          project_id: this.form.project_id,
          user_id: this.form.user_id,
          ...this.params,
        },
      })
          .then((response) => {
            if (this.dateRange[0] === null) {
              this.dateRange = [response.data.start, this.dateRange[1]];
            }

            vm.option = response.data.option;
            this.issues = response.data.result.data.map(item => ({
              ...item,
              'time_entries.hours': this.remFromTimestring(item['time_entries.hours'], 'seconds'),
              'issues.created_at': this.remFromTimestring(item['issues.created_at'], 'hours'),
            }));
            vm.totalHours = response.data.totalHours;
            //set document form
            vm.documentForm.date_picker = vm.form.date_picker;
            vm.documentForm.start_date = dateStart;
            vm.documentForm.due_date = dateEnd;
            vm.documentForm.status_id = vm.form.status_id;
            vm.documentForm.project_id = vm.form.project_id === '' ? null : vm.form.project_id;
            vm.documentForm.user_id = vm.form.user_id;

            vm.params.total = response.data.result.total;
            vm.loading = false;
          })
          .catch(function (error) {
            vm.loading = false;
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    remFromTimestring(time, type) {
      if (time) {
        if (type === 'hours') {
          return time.substring(0, time.length - 9);
        }
        if (type === 'seconds') {
          return time.substring(0, time.length - 4);
        }
      }
      return '';
    },
    showModalTask: function (val) {
      if (val['issues.deleted_at']) {
        this.notifyAboutDeletedIssue();
      } else {
        let query = _.cloneDeep(this.$route.query);
        query.issueId = val['issues.id'];
        this.$router.push({path: this.$route.path, query: query});
      }
      // this.$store.dispatch('generator/mode', {mode: 'R'});
      // window.history.pushState({params: {issueId: row['issues.id']}}, row['issues.subject'], '/issue/' + row['issues.id']);
      // this.idShowModalTask = row['issues.id'];
      // this.showIssueModal = true;
    },
    refresh() {
      Vue.nextTick(() => {
        this.fetchIssues();
      });
    },
    close() {
      // this.showIssueModal = false;
      // this.showIssueEdit = false;
    },
    openShow(data) {
      if (data.id) {
        this.id = data.id;
      }
      this.showIssueModal = true;
      if (this.$route.params.projectId) {
        window.history.pushState({
          params: {
            issueId: this.id,
            projectId: data.project_id,
          },
        }, '', '/project/' + data.project_id + '/issue/' + this.id);
      } else {
        window.history.pushState({params: {issueId: this.id}}, '', '/issue/' + this.id);
      }
    },
    openEdit(data) {
      if (data.id) {
        this.id = data.id;
      }
      this.showIssueEdit = true;
      if (this.$route.params.projectId) {
        window.history.pushState({
          params: {
            issueId: this.id,
            projectId: data.project_id,
          },
        }, '', '/project/' + data.project_id + '/issue/' + this.id + '/edit');
      } else {
        window.history.pushState({params: {issueId: this.id}}, '', '/issue/' + this.id + '/edit');
      }
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.report--selectFilter {
  .el-select {
    width: auto;

    span:first-child {
      display: flex;
      justify-content: center;
    }

    .el-tag:first-child {
      display: flex;
      align-items: center;

      .el-select__tags-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        max-width: 100px;
      }
    }
  }
}

</style>
